/** @jsx jsx */

import { jsx } from 'theme-ui';
import { PageRoot, ContentNode } from "../data/content"
import { IndexPageContainer } from "../components/indexes"
import { Documents } from "../components/documents"
import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { useLocalisedContentViewer, useLocalisedContent } from '../components/featured';
import SEO from '../components/seo';
import { PillarLogo } from '../images/pillars';

const BlueprintCollection: PageRoot<{ node: ContentNode }> = ({
  data: { node },
  data: { node: { frontmatter: {
    collectionDocuments, framingDocument,
  } } },
  pageContext: { locale, locales },
  location: { pathname }
}) => {
  useLocalisedContentViewer(locale, locales)
  const localisedContent = useLocalisedContent(node, locale)
  const formattedAbstract = localisedContent.abstract &&
    localisedContent.abstract.split('\n').map(p => <p key={p}>{p}</p>)

  return (
    <IndexPageContainer
      heading={<Fragment>{formattedAbstract}</Fragment>}
      title={localisedContent.title}
      bg="blueprintHexLight"
      color="blueprintHex"
      icon={
        <PillarLogo sx={{ color: "blueprintHex", size: [40, 70, 80, 100] }} type={'blueprint'} />
      }
    >
      <SEO
        path={pathname}
        title={localisedContent.title}
        description={localisedContent.shortAbstract}
      />
      {framingDocument && collectionDocuments?.length &&
        <Documents
          documents={collectionDocuments}
          framingDocument={framingDocument}
        />
      }
    </IndexPageContainer>
  )
}

export const pageQuery = graphql`
  query BlueprintCollection($id: String!) {
    node: markdownRemark(id: { eq: $id }) {
      ...ContentPageFragment
    }
  }
`

export default BlueprintCollection
