/** @jsx jsx */

import { jsx } from 'theme-ui';
import { Rows, ViewElement } from "../components/atoms"
import { ContentNode } from "../data/content"
import { Grid } from "theme-ui"
import {
  PrimaryFeatured,
  SecondaryFeatured,
} from "./featured"
import { MobileContentBlock } from "./page"

export const Documents: ViewElement<{
  documents: ContentNode[]
  framingDocument: ContentNode
  columns?: number | number[]
}> = ({
  documents,
  framingDocument,
  columns = [1, null, 2]
}) => (
    <MobileContentBlock>
      <Rows gap={5} sx={{ width: "100%" }}>
        <PrimaryFeatured node={framingDocument} />
        <Grid gap={5} columns={columns}>
          {documents.map((node) => (
            <SecondaryFeatured key={node.id} node={node} />
          ))}
        </Grid>
      </Rows>
    </MobileContentBlock>
  )